<template>
  <div>
    <el-dialog :visible.sync="show">
      <div class="delete-contect">
        <div class="delete-icon row">
          <p class="icon-border row">
            <i class="el-icon-warning-outline"></i>
          </p>
        </div>
        <p class="delete-text">{{title}}</p>
        <div class="del-btn">
          <el-button class="light-blue-button" @click="show = false" >No</el-button>
          <el-button class="primary-button" @click="del" :loading="loading">Yes</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  props:["title"],
  data() {
    return {
      show: false,
      loading: false,
    };
  },
  methods:{
      del(){
          this.$emit("del")
      }
  }
};
</script>
<style lang="scss" scoped>
@import "../../css/button.scss";
/deep/ .el-dialog__title{
  height: 20px;
  display: inherit;
}
.delete-contect {
  .delete-icon {
    justify-content: center;
    .icon-border {
      justify-content: center;
      width: 70px;
      height: 70px;
      border-radius: 50%;
      background-color: rgba(222, 27, 75, 0.2);
      i {
        color: rgb(222, 27, 75);
        font-size: 38px;
      }
    }
  }
  .delete-text {
    font-size: 20px;
    color: #36425d;
    font-family: "Roboto-Bold", sans-serif;
    margin: 0 50px 20px 50px;
  }
  .del-btn {
    text-align: center;
    .el-button {
      width: 100px;
    }
  }
}
/deep/ .el-dialog{
  width: 400px;
  @media (max-width:992px) {
    width: 90%;
  }
}
</style>